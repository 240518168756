// extracted by mini-css-extract-plugin
export var column = "Sitemap__column__G4b94";
export var container = "Sitemap__container__DqLd3";
export var flex = "Sitemap__flex__iaEqf";
export var flexColumn = "Sitemap__flexColumn__dHRQK";
export var gap1 = "Sitemap__gap1__iNe3O";
export var gap2 = "Sitemap__gap2__NIZQT";
export var gap3 = "Sitemap__gap3__xYtyV";
export var gap4 = "Sitemap__gap4__M3pLF";
export var gap5 = "Sitemap__gap5__HoX6X";
export var h1 = "Sitemap__h1__SyRxh";
export var h2 = "Sitemap__h2__b1v7_";
export var row = "Sitemap__row__mlC1O";
export var sitemapUrl = "Sitemap__sitemapUrl__kIK6c";
export var threeColumnList = "Sitemap__threeColumnList__ynPkz";